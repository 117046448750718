:root {
  --primary: #b6895b;
  --bg: #454444;
}

.myBG {
  height: 100vh;
  /* background: url(../asets/image/transformer.jpg); */
  background-size: cover;
  background-position: center;
  /* transform: translate(-50% -50%); */
}
.navi {
  border-bottom: 2px solid black;
  background-color: rgba(1, 1, 1, 0.7);
  width: 100%;
  /* top: 0;
  left: 0;
  z-index: 9999;
  transition: all 300ms ease-in-out; */
}
.navi a {
  color: #fff;
}
.navi .b {
  color: #fff;
}
.navi .b:hover {
  color: var(--primary);
}

.navi a:hover {
  color: var(--primary);
}

.navi .b::after {
  content: "";
  display: block;
  padding-bottom: 0.3rem;
  border-bottom: 0.1rem solid var(--primary);
  transform: scaleX(0);
  transition: 0.2s linear;
}

.navi .b:hover::after {
  transform: scaleX(0.5);
}
.films {
  height: auto;
  background: url(../asets/image/bg.jpg);
  background-size: cover;
  background-position: center;
}

.intro {
  height: 40vh;
  display: flex;
  justify-content: end;
  align-items: end;
}

.trending {
  height: auto;
  background: url(../asets/image/skull.jpg);
  background-size: cover;
  background-position: center;
}

.trend {
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading {
  margin: 50px;
  padding: 50px;
}
/* .movieWraper {
} */

.movieImage {
  height: auto;
  margin: 5px;
  padding: 5px;
}

/* Style untuk input search */
/* .search-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  width: 200px;
  margin-right: 10px;
  font-size: 14px;
  outline: none;
}

.search-icon {
  cursor: pointer;
  color: #fff;
  font-size: 20px;
} */

.search-input {
  width: 100%;
  padding: 8px 30px 8px 8px; /* Padding for space between icon and input text */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
}

.search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  right: 250px; /* Adjust the position of the icon */
  color: #555;
  cursor: pointer;
}

.scroll-down {
  transform: translate3d(0, -100%, 0);
}

.scroll-up {
  filter: drop-shadow(0, -10px 20px rgb(170, 170, 170));
}
